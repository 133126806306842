import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import rapportComponent from '../Components/Rapport/rapportComponent';


function mapStateToProps(state) {
  return { auth: state.auth };
};


class RapportRouter extends Component {

	
	render() {
		return(
				<Switch>

					{(!this.props.auth ? <Redirect to="/login" /> : "")}
                        <Route exact path='/rapport' component = {rapportComponent}	/>			
				</Switch>


			
		)
	}
}

export default connect(mapStateToProps)(RapportRouter)