import React from "react"
import {connect} from "react-redux"
import { setAuthAction, startLoading, doneLoading } from './../../Redux/actions/index';
import { Link } from 'react-router-dom';

import api from '../../Utils/Api';

const mapStateToProps = state => {
	return {
		auth: state.auth,
		user: state.user
	}
}


const mapDispatchToProps = dispatch => {
	return {
		isLoading: e => dispatch(startLoading(e)),
		doneLoading: e => dispatch(doneLoading(e))
	}
}


class AgentOverview extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			status: null,
			fullname: null,
			tasks: null,
			lastonline: null,

		}

		props.isLoading();
	}

	componentDidMount() {

		const that = this;

		api.get('/api/user/'+this.props.match.params.userid+'/overview?token='+this.props.user.token)
		.then(function(response) {
			if(response.data.success) 
				that.setState({...response.data.data, 'loaded': true})

			
		}).then(r => that.props.doneLoading()); 
	}

    render() {
		if(this.state.loaded)
        return (
		<div>
            <div className={"col-md-6"}>
			
  				<h4><span className={"status-ball status-"+ this.state.status_id} /> Agent </h4>


				<div className={"row"}>
					<div className={"col-md-4"}>
						{this.state.status_id === 4 && this.state.status_current &&
						(<div>Current task : <Link to={"/task/"+this.state.status_current}>#{this.state.status_current}</Link></div>)
						}
						
						<div>{this.state.fullname}</div>
					</div>
				
				</div>
  				


  			</div>

        </div>
		)

		return ""
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AgentOverview)