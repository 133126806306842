// To deploy in local
//export const REMOTE_API = "http://127.0.0.1:8000/";

// To deploy in the real
//export const REMOTE_API = "https://api.witrack.tictechs.tech/";
export const REMOTE_API = "https://api.anderson.witrack.link/";
// To deploy in Anderson
//export const REMOTE_API = "https://api.witrack2.tictechs.tech/";

// To deploy in .degital
//export const REMOTE_API = "https://api.witrack.digital/";

export const REMOTE_SOCKET = "https://ws.sts.tictechs.tech";
export const REMOTE_STATIC = "https://api.sts.tictechs/";
export const COMPANY_NAME = "Anderson";
export const APP_NAME = "WiTrack";

// export const REMOTE_API = "http://localhost/sts-sym/public/";//"https://api.sts.tictechs.tech/";