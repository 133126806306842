import React, {useState, Component } from "react";
import _ from "lodash";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import TaskList from "../Components/Task/TaskList";
import { setAuthAction, startLoading, doneLoading } from "../Redux/actions/index";
import { Trans } from "react-i18next";
import Select from "react-select";
import SocketContext from "../Utils/socket-context";
import api from "../Utils/Api";
import Pagination from "../Utils/Pagination";
import { loadCommonData, buildUriParams } from "../Utils/Helpers";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faRedo } from "@fortawesome/free-solid-svg-icons";
import TaskNewContainer from "./TaskNewContainer";
import communes from "../../assets/communes.json";

import WTNotifications, { withNotifications } from "react-socket-notification";
import ClientInput from "../Components/Task/subcomponents/ClientInput";
import equal from 'fast-deep-equal'
import MultiSelect from "react-multi-select-component";



const mapStateToProps = state => {
  return {
    auth: state.auth,
    user: state.user,
    taskList: state.taskList,
    filters: state.filters,
    taskStatus: state.taskStatus,
    task_status: state.task_status
  };
};

function mapDispatchToProps(dispatch) {
  return {
    isLoading: e => dispatch(startLoading(e)),
    doneLoading: e => dispatch(doneLoading()),
    setAuth: e => dispatch(setAuthAction(e))
  };
}

const moment = require("moment");





class TaskListContainer extends Component {
  constructor(props) {
    super(props);



    this.props.isLoading({
      message: "Loading tasks",
      animate: true
    });

    this.state = {
      managers: [],
      tasks: [],
      agents: [],
      labelVehicule: [],
      agentsAssign: [],
      status: [],
      types: [],
      clients: [],
      rows: [],
      current: 1,
      pages: 1,
      limit: 60,
      filters: {},
      isClearable:true,
    };

    this.changeLimit = this.changeLimit.bind(this);

    this.handlers = {
      selectPage: this.selectPage.bind(this),
      loadTasks: this.loadTasks, 
      loadTasksFromDoc: this.loadTasksFromDoc
    };
    console.log(this.props.match.params.id)
    
  }

  updateFilter = (event, nameVal, isDate = false ,arr = false) => {
    let that = this;
    console.log(event);
    let value = "";

    if (isDate) {
      value = event;
    } else {
      value = event ? (event.target ? event.target.value : event.value) : '';
    }
    if(!arr){
      this.setState(
        {
          filters: {
            ...this.state.filters,
            [nameVal]: value
          }
        }
      );
    }
      
    // if (nameVal === 'type_vehicule'){
    //   setTimeout(() => {  this.loadTasks(); }, 1);
    // }
     

  };



  handleEscape = (e) => {
    if (e.keyCode === 27) {
      this.setState({ showNewTask: false });
    }
  };


  componentDidMount() {
    this.searchTaskById();
    document.addEventListener("keyup", this.handleEscape);
    loadCommonData();

    let that = this;

    if (this.props.taskList.length > 0) {
      this.setState({ tasks: this.props.taskList });
    } else {
      this.loadTasks();
    }

    if (this.props.socket) {
      console.log("socket connected");

      this.props.socket.on("task_status", function(data) {
        console.log("event: ", data);
        if (data.type && data.type === "task_status") that.loadTasks();
      });
    } else {
      console.log("not connected");
    }

    api.get("/api/v2/task/form").then(function(response) {
      if (response.data.success) {
        that.setState(
          {
            agentsAssign: [{ label: "All", value: 0 }, ...response.data.agentsAssign],
            agents: [{ label: "All", value: 0 }, ...response.data.agents],
            types: [{ label: "All", value: 0 }, ...response.data.types],
            status: [{ label: "All", value: 0 }, ...response.data.status],
            clients: [{ label: "All", value: 0 }, ...response.data.client],
            labelVehicule: [{ label: "All", value: 0 }, ...response.data.vehicule]
          });

        if (response.data.managers) {
          that.setState({
            managers: [{ label: "All", value: 0 }, ...response.data.managers]
          });
        }
      }
    });
  }

  loadTasks = (p = this.state.current, e, redirect=false) => {
    if(e)
      e.preventDefault()

    this.props.isLoading();
    const params = buildUriParams(this.state.filters);
    console.log(params);
    const crits = "limit=" + this.state.limit + params;


    api.get("/api/v2/task/fetch?page=" + p + "&" + crits).then((response) => {
      if (response.data.success) {
        this.setState({
          tasks: response.data.tasks,
          pages: response.data.pages,
          total: response.data.total
        });
      } else {
        if (response.data.errcode === 101) {
          this.props.setAuth(false);
        }
      }

      this.props.doneLoading();
      // console.log(this.state.tasks);
    });
    if(redirect){
      this.props.history.push("/task");
    }
    //
    return false;
  };

  loadTasksFromDoc = (p = this.state.current) => {

    const params = buildUriParams(this.state.filters);
    console.log(params);
    const crits = "limit=" + this.state.limit + params;


    api.get("/api/v2/task/fetch?page=" + p + "&" + crits).then((response) => {
      if (response.data.success) {
        this.setState({
          tasks: response.data.tasks,
          pages: response.data.pages,
          total: response.data.total
        });
      } else {
        if (response.data.errcode === 101) {
          this.props.setAuth(false);
        }
      }

      // console.log(this.state.tasks);
    });
    //
    return false;
  };

  


  componentDidUpdate(prev) {
    if(!equal(this.props.match.params.id, prev.match.params.id)){
      this.searchTaskById();
    }
    const that = this;

    if (this.props.socket && !prev.socket) {
      this.props.socket.on("task_status", function(data) {
        if (data.type && data.type === "task_status") that.loadTasks();
      });
    }
  }

  componentWillUnmount() {
    if (this.props.socket) {
      this.props.socket.off("task_status");
    }
    document.removeEventListener("keyup", this.handleEscape);
  }

  loadForm(ent) {
    let that = this;

    api.get("/api/v2/task/form").then(function(response) {
      if (response.data.success)
        that.setState({
          agents: response.data.agents,
          types: response.data.types
        });
    });
  }

  changeLimit = e => {
    this.props.isLoading();
    this.setState(
      {
        limit: e.value,
        current: 1
      },
      () => this.loadTasks()
    );
  };

  selectPage = p => {
    this.props.isLoading();
    this.setState({ current: p });
    this.loadTasks(p);
  };

  formatDate = date => (date ? new Date(date) : undefined);

  afterPost = () => {
    this.loadTasks();
    this.setState({
      showNewTask: false,
      is_duplicate: false
    });
  };

  selectDate = when => {
    if (when === "yesterday") {
      this.setState(
        state => ({
          ...state,
          filters: {
            ...state.filters,
            date_debut: moment()
              .add(-1, "day")
              .format("YYYY-MM-DD 00:00"),
            date_fin: moment()
              .add(-1, "day")
              .format("YYYY-MM-DD 00:00")
          }
        }),
        () => {
          this.props.isLoading();
          this.loadTasks();
        }
      );
    } else if (when === "today") {
      this.setState(
        state => ({
          ...state,
          filters: {
            ...state.filters,
            date_debut: moment().format("YYYY-MM-DD 00:00"),
            // date_fin: moment()
            //   .add(1, "day")
            //   .format("YYYY-MM-DD 00:00")
            date_fin: moment().format("YYYY-MM-DD 00:00")
          }
        }),
        () => {
          this.props.isLoading();
          this.loadTasks();
        }
      );
    } else if (when === "tomorrow") {
      this.setState(
        state => ({
          ...state,
          filters: {
            ...state.filters,
            date_debut: moment()
              .add(1, "day")
              .format("YYYY-MM-DD 00:00"),
            date_fin: moment()
              .add(1, "day")
              .format("YYYY-MM-DD 00:00")
          }
        }),
        () => {
          this.props.isLoading();
          this.loadTasks();
        }
      );
    }
  };

  resetFilters = () => {
    this.setState(state => ({ ...state, filters: {} }), this.loadTasks);
  };


  duplicateTask = id => {
    this.setState({
      showNewTask: true,
      selected_task: id,
      is_duplicate: true
    })
  }

  searchTaskById = ()=> {
    let taskId = 'task_id' 
    let params = this.props.match.params.id;
    this.state.filters['task_id'] = params

    // this.props.isLoading();
    // const querys = buildUriParams(this.state.filters);
    // console.log(querys);
    // const crits = "limit=" + this.state.limit + querys;


    // api.get("/api/v2/task/fetch?page=" + this.state.current + "&" + "limit=" + this.state.limit + "&" + "task_id=" + this.props.match.params.id).then((response) => {
    //   if (response.data.success) {
    //     this.setState({
    //       tasks: response.data.tasks,
    //       pages: response.data.pages,
    //       total: response.data.total
    //     });
    //   } else {
    //     if (response.data.errcode === 101) {
    //       this.props.setAuth(false);
    //     }
    //   }

    //   this.props.doneLoading();
    // });
    this.loadTasks();
  }

  multiSelectHandler = (event, nameVal) =>{
    
    let arr = []
    console.log("event not option =>",event)
    for (var i = 0; i < event.length; i++ ){
      console.log("for loop =>",event[i].value)
      arr = arr.concat([event[i].value])
      console.log(arr)
      
    }
    this.setState(
        {
          filters: {
            ...this.state.filters,
            [nameVal]: arr
          }
        }
      );
      
    
  }

  toggleClearable = () =>
    this.setState((state) => ({ isClearable: !state.isClearable }));
 
  render() {
    
    const locations = communes.communes.map(item => ({
      label: item.nom + " (" + item.code_postal + ")",
      value: item.nom
    }));
    const {filters} = this.state;
    var selectedValue = [];
    var v = 2;

    return (
      <React.Fragment>
        <div className={"container-header"}>
          <Trans i18nKey="label_tasks" />

          {!this.props.auth ? <Redirect to="/login" /> : ""}

          {this.props.user &&
          (this.props.user.role === "manager" ||
            this.props.user.role === "commercial" ||
            this.props.user.role === "admin") ? (
            <React.Fragment>
              <button type="button" onClick={() => this.setState({ showNewTask: true })} className={"cbtn"}>
                Ajouter une nouvelle tâche
              </button>
              {/* <Link to={"/task/new"} className={"cbtn float-right"}>
                <Trans i18nKey="button_new_task" />
              </Link> */}
            </React.Fragment>
          ) : (
            ""
          )}
        </div>

        <React.Fragment>
          {this.props.user.role === "admin" ? (
            <div className={"row mb-5"}>
              <div className={"col-md-3"}>
                <label>Manager</label>
                <Select
                  className={"custom-select-c "}
                  options={this.state.managers}
                  isClearable={this.state.isClearable}
                  placeholder={"Created by"}
                  onChange={e => this.updateFilter(e, "author")}
                />
              </div>
            </div>
          ) : (
            ""
          )}

          <div className={"form ui-rect np"}>
            <form onSubmit={e => this.loadTasks(1, e,true)}>
            <div className={"row"}>
              <div className={"col-md-3"}>
                <Select
                  isMulti
                  options={this.state.agents}
                  placeholder="Agent"
                  //value={this.state.agents.filter(item => item.value === filters.agent)}
                  //onChange={e => this.updateFilter(e, "agent")}
                  onChange={e => this.multiSelectHandler(e, "agent")}
                />
              </div>

              <div className={"form-group col-md-2"}>
                <Select
                  isMulti
                  options={[{ label: "Tout", value: "" }, ...this.props.taskStatus]}
                  placeholder={"Status assignement"}
                  isClearable={this.state.isClearable}
                  // value={this.props.taskStatus.filter(item => item.value === filters.status)}
                  // onChange={e => this.updateFilter(e, "status")}
                  onChange={e => this.multiSelectHandler(e, "status")}
                />
              </div >
              <div className={"form-group col-md-2"}>
                <Select
                  isMulti
                  options={[...this.props.task_status]}
                  placeholder={"Status course"}
                  isClearable={this.state.isClearable}
                  //value={this.props.task_status.filter(item => item.value === filters.task_status)}
                  //onChange={e => this.updateFilter(e, "task_status")}
                  onChange={e => this.multiSelectHandler(e, "task_status")}
                  
                  
                />
                {/* <MultiSelect
                classNamePrefix={"custom-select"}
                className={"custom-select-c"}
                  options={[{ label: "Tout", value: "" }, ...this.props.task_status]}
                  placeholder={"Status course"}
                  value={this.props.task_status.filter(item => item.value === filters.task_status)}
                /> */}
                
                
               

                </div>

              <div className={"form-group col-md-2"}>
                    <Select
                      isMulti
                      options={this.state.clients}
                      placeholder="client"
                      isClearable={this.state.isClearable}
                      //onChange={e => this.updateFilter(e, "client")}
                      onChange={e => this.multiSelectHandler(e, "client")}
                    />
                
              </div>

              <div className={"form-group col-md-3"}>
                {/* <input
                  placeholder={"Client"}
                  onChange={e => this.updateFilter(e, "client")}
                  value={filters.client || ""}
                  name="name"
                  type="text"
                  className={"form-control"}
                /> */}

                    {/* <ClientInput
                      placeholder={"Client"}
                      required={false}
                      defaultValue={filters.client || ""}
                      selectClient={sug => this.updateFilter({value: sug.client_name}, "client")}
                      type="text"
                      name="task_client"
                      className={"form-control col-md"}
                    /> */}
                    <input
                      placeholder={"Réference"}
                      onChange={e => this.updateFilter(e, "name")}
                      value={filters.name}
                      name="name"
                      type="text"
                      className={"form-control"}
                    />

              </div>
            </div>

            <div className={"row"}>
              <div className={"col-md-3 form-group"}>
                <Select
                  // classNamePrefix={"custom-select"}
                  // className={"custom-select-c "}
                  isClearable={this.state.isClearable}
                  options={[
                    { label: "Tout", value: "" },
                    { label: "Léger", value: "P" },
                    { label: "Lourd", value: "L" }
                  ]}
                  value={[
                    { label: "Tout", value: "" },
                    { label: "Léger", value: "P" },
                    { label: "Lourd", value: "L" }
                  ].filter(item => item.value === filters.type_vehicule)}
                  placeholder={"Type véhicule"}
                  onChange={e => this.updateFilter(e, "type_vehicule")}
                />
              </div>
              <div className={"col-md-2 form-group"}>
                <Select
                  isMulti
                  isClearable={this.state.isClearable}
                  options={this.state.labelVehicule}
                  placeholder={"Label véhicule"}
                  onChange={e => this.multiSelectHandler(e, "vehicule_label")}
                />
              </div>

              <div className={"col-md-2 form-group"}>
                <Select
                  // classNamePrefix={"custom-select"}
                  // className={"custom-select-c "}
                  isClearable={this.state.isClearable}
                  options={[
                    { label: "Tout", value: "" },
                    { label: "Document reçu", value: "true" },
                    { label: "Document non reçu", value: "false" }
                  ]}

                  placeholder={"Retour Document"}
                  onChange={e => this.updateFilter(e, "docs")}
                />
              </div>

              <div className={"col-md-2 form-group"}>
                <div className={"dates-wrap"}>
                  <ReactDatePicker
                    dateFormat={"yyyy-MM-dd"}
                    placeholderText={"Date début"}
                    timeFormat={"HH:mm"}
                    showTimeSelect={false}
                    className={"form-control"}
                    selected={this.formatDate(filters.date_debut)}
                    onChange={e =>
                      this.updateFilter(moment(new Date(e)).format("YYYY-MM-DD"), "date_debut", true)
                    }
                    selectsStart
                    startDate={this.formatDate(filters.date_debut)}
                    endDate={this.formatDate(filters.date_fin)}
                    wrapperClassName={"col-md-12"}
                  />
                </div>
              </div>
              <div className={"col-md-3 form-group"}>
                <ReactDatePicker
                  timeFormat={"HH:mm"}
                  dateFormat={"yyyy-MM-dd"}
                  placeholderText={"Date fin"}
                  showTimeSelect={false}
                  className={"form-control"}
                  selected={this.formatDate(filters.date_fin)}
                  onChange={e => this.updateFilter(moment(new Date(e)).format("YYYY-MM-DD"), "date_fin", true)}
                  selectsEnd
                  startDate={this.formatDate(filters.date_debut)}
                  endDate={this.formatDate(filters.date_fin)}
                  minDate={this.formatDate(filters.date_debut)}
                  wrapperClassName={"col-md-12"}
                />
              </div>
            </div>

            <div className={"row"}>
              <div className={"col-md-2"}>
                <div className={"form-group"}>
                  <Select
                    value={locations.filter(item => item.value === filters.depart)}
                    // classNamePrefix={"custom-select"}
                    // className={"custom-select-c "}
                    isClearable={this.state.isClearable}
                    options={locations}
                    placeholder={"Commune départ"}
                    onChange={e => this.updateFilter(e, "depart")}
                    cacheOptions={true}
                  />
                </div>
              </div>

              <div className={"col-md-2"}>
                <div className={"form-group"}>
                  <Select
                    // classNamePrefix={"custom-select"}
                    // className={"custom-select-c "}
                    isClearable={this.state.isClearable}
                    options={locations}
                    placeholder={"Commune arrivé"}
                    onChange={e => this.updateFilter(e, "arrive")}
                    cacheOptions={true}
                  />
                </div>
              </div>
              <div className={"col-md-2"}>
              <Select
                className={"custom-select-c "}
                options={this.state.managers}
                isClearable={this.state.isClearable}
                placeholder={"Créé par"}
                onChange={e => this.updateFilter(e, "manager")}
              />
              </div>
              <div className={"col-md-2"}>
              <Select
                options={[
                  { label: "Status cours", value: "task_status" },
                  { label: "Date", value: "dateCommande" },
                  { label: "Client", value: "client" },
                  { label: "Status assignement", value: "status" }
                ]}
                isClearable={this.state.isClearable}
                placeholder={"Trier par"}
                onChange={e => this.updateFilter(e, "sort")}
              />
              </div>

              <div className={"col-md-4 text-right"}>
                <button onClick={() => this.selectDate("yesterday")} className={"btn btn-small btn-link"}>
                  Hier
                </button>
                <button onClick={() => this.selectDate("today")} className={"btn btn-small btn-link"}>
                  Aujourd'hui
                </button>
                <button onClick={() => this.selectDate("tomorrow")} className={"btn btn-small btn-link"}>
                  Demain
                </button>
                <button onClick={this.resetFilters} className={"btn btn-sm btn-outline-primary"}>
                  Reinitialiser les filtres
                </button>
                <button onClick={() => this.loadTasks(1)} className={"btn btn-sm btn-primary ml-2"}>
                  Filtrer
                  
                </button>
                {/* <button onClick={() => this.loadTasks(1)} className={"btn btn-sm btn-success ml-2"}>
                  Exporter
                </button> */}
              </div>
            </div>
            </form>
          </div>
        </React.Fragment>

        <div className={"row ui-rect my-4"}>
          <div className={"col-md-6 items-found"}>
            <p>
              <b>{this.state.total}</b> tâches trouvés
            </p>
          </div>

          <div className={"col-md-6 per-page-wrap"}>
          
           
            
            <div className={"per-page-content"}>
              <p>Résultat par page</p>
              <Select
                classNamePrefix={"custom-select"}
                onChange={this.changeLimit}
                className={"per-page nm"}
                options={this.props.filters.result_per_page}
                value={{ label: this.state.limit, value: this.state.limit }}
              />
            </div>
            <Pagination data={this.state} handleClick={this.selectPage} />
          </div>
        </div>

        <WTNotifications
          connection={this.props.connection}
          event={'new'}
          condition={(message) => message === "new_tasks"}
          component={(message, active, close) => <RenderNotification
                                                    refresh={() => this.loadTasks(1)}
                                                    active={active}
                                                    close={close}
                                                    message={message}
                                                  />}
          alwaysActive
        />

        <div className={"ui-rect np"}>
          <TaskList duplicateTask={this.duplicateTask} data={this.state} rows={this.state.rows} handlers={this.handlers} />
        </div>

        {this.state.showNewTask && (
          <div className={`ui-overlay open`}>
            <div className={"ui-overlay-content task-detail col-md-10 offset-md-1"}>
              <button
                onClick={() => this.setState({ showNewTask: false })}
                className={"ui-overlay-close btn btn-danger btn-sm"}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>

              <TaskNewContainer taskId={this.state.selected_task} isDuplicate={this.state.is_duplicate} inline={true} afterPost={this.afterPost} />
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const TaskListConsumer = props => (
  <SocketContext.Consumer>{socket => <TaskListContainer {...props} socket={socket} />}</SocketContext.Consumer>
);

const RenderNotification = ({message, active, close, refresh}) => {


  return (
    <>
      <div onClick={close} className={"task-notifications "+ (active && "active")}>

        <button onClick={refresh} className={"cbtn"} type="button">
          Nouvelles mise à jours disponible
          <FontAwesomeIcon className={"ml-2"} icon={faRedo} />
        </button>
      </div>
    </>
  )
}

const newComp = withNotifications(TaskListContainer);
export default withNotifications(connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskListContainer));
