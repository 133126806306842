import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';





class AgentTopMenu extends Component {
	constructor(props) {
		super(props);

		this.state = {
			menuOpen: false
		}


		this.menuRef = React.createRef();
		this.blur = this.blur.bind(this);
		this.toggleMenu = this.toggleMenu.bind(this);

	}

	blur() {
		this.setState({menuOpen: false});
	}


	toggleMenu() {
		this.setState({menuOpen: !this.state.menuOpen});
		
	}


	render() {
		const menuOpen = this.state.menuOpen;
		
		return (
			<div>

				<ul className="header-menu">					
					<li><Link to="/">Home </Link></li>
					<li><NavLink to="/agent/tasks">Tasks </NavLink></li>
					<li><Link to="/account">Profile </Link></li>
				</ul>

				<ul className={"user-profile"} >
					<li onClick={this.toggleMenu}>
					 
					Signed in as <span className={"user-profile-username"}> {this.props.user.username}</span>
					<span className={"user-profile-avatar"}><img src={"/img/profile.jpg"} /></span>

					<ul  className={menuOpen === true ? "user-profile-menu open" : "user-profile-menu"} ref={this.menuRef} onBlur={this.blur}>
						<li><Link to="/account">My Account</Link></li>
						<li><Link to="/">Change my password</Link></li>

						<li><a href="javascript:void(0)"  onClick={e => this.props.logout(false)} >Logout</a></li>
						<li className={"sep"}></li>
						<li><Link to="/">Help</Link></li>

					</ul>
					</li>
					<li><a href="#"></a></li>
				</ul>

				<div className={"clear"}></div>
			</div>
		)
	}
}

export default AgentTopMenu