import React from "react";
import ClientAddressRow from "./ClientAddressRow";
import api from "../../Utils/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faCircle } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { startLoading, doneLoading } from "../../Redux/actions";

class ClientDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      client: {},
      addresses: [],
      addressesLoading: true
    };
  }

  loadAddresses = () => {
    const clientid = this.props.match.params.id;

    api.get(`/api/client/${clientid}/address`).then(res => {
      console.log(res);
      this.setState({
        addressesLoading: false,
        addresses: res.data.data
      });
    });
  };

  loadClientDetails = () => {
    const clientid = this.props.match.params.id;

    api.get(`/api/client/${clientid}`).then(res => {
      console.log(res);
      this.setState({
        addressesLoading: false,
        client: res.data.data
      });
    });
  };


  saveClient = () => {

  }


  submitClient = e => {
    e.preventDefault();

    const formData = new FormData(e.target);

    let data = {
      clientid: this.props.match.params.id
    };

    this.props.isLoading({
      message: "Sauvegarde en cours",
      animate: true
    });

    for (var [key, value] of formData.entries()) data[key] = value;


    api.post('/api/client/save', data)
    .then(res => {
      this.props.doneLoading();
    })


  }

  componentDidMount() {
    this.loadClientDetails();
    this.loadAddresses();
  }

  addNewAddres = () => {
    const { addresses } = this.state;

    addresses.reverse();
    const obj = {
      open: true,
      address_label: "",
      address_address: "",
      address_id: "",
      address_contact_name: "",
      address_contact_phone: "",
      address_wilaya: "",
      address_commune: ""
    };

    addresses.push({ ...obj });
    addresses.reverse();

    console.log(addresses);

    this.setState({ addresses: addresses });
  };

  updateAddressField = (idx, field, event) => {
    const addresses = [...this.state.addresses];

    // const addr = {...this.state.addresses[idx]}

    if (field !== "mustSave") addresses[idx].mustSave = true;

    addresses[idx][field] = event.target
      ? event.target.value
      : event.value
      ? event.value
      : event;

    this.setState({ addresses });
  };

  saveAddress = idx => {
    this.updateAddressField(idx, "isSaving", true);

    const address = { ...this.state.addresses[idx] };

    const clientid = this.props.match.params.id;
    api
      .post(`/api/client/${clientid}/address/save`, { address: address })
      .then(res => {
        console.log(res.data);
        this.updateAddressField(idx, "isSaving", false);
        this.updateAddressField(idx, "mustSave", false);
      })
      .catch(err => {
        this.updateAddressField(idx, "isSaving", false);
      });
  };

  removeAddress = idx => {
    const { addresses } = this.state;

    addresses.splice(idx, 1);
    this.setState({ addresses });
  };

  deleteAddress = idx => {
    const { addresses } = this.state;

    const aid = addresses[idx].address_id;

    if (!aid) {
      this.removeAddress(idx);
      return;
    } else {
      this.setState({
        addressesLoading: true
      });

      api.delete(`/api/address/${aid}`).then(response => {
        this.setState({
          addressesLoading: true
        });

        this.removeAddress(idx);
      });
    }
  };

  render() {
    return (
      <>
        <div className={"container-header"}>Client details</div>

        <div className={"row"}>
          <div className={"col-md-6 np"}>
            <div className={" ui-rect np"}>
              <form onSubmit={this.submitClient}>
                <div className={"form-group"}>
                  <label>Client</label>
                  <input
                    defaultValue={this.state.client.client_name}
                    name="client_name"
                    type={"text"}
                    className={"form-control"}
                  />
                </div>

                <div className={"form-group"}>
                  <label>Contact principal</label>
                  <input
                    defaultValue={this.state.client.client_contact}
                    name="client_contact"
                    type={"text"}
                    className={"form-control"}
                  />
                </div>

                <div className={"form-group"}>
                  <label>Téléphone</label>
                  <input
                    defaultValue={this.state.client.client_phone}
                    name="client_phone"
                    type={"text"}
                    className={"form-control"}
                  />
                </div>

                <div className={"form-group"}>
                  <button className={"btn btn-success"}>Ajouter</button>
                </div>
              </form>
            </div>
          </div>

          <div className={"col-md-6"}>
            <div className={" ui-rect"}>
              <div className={"rect-header"}>
                <h4>
                  Adresses
                  {this.state.addressesLoading && (
                    <FontAwesomeIcon
                      style={{ marginLeft: 15 }}
                      spin={true}
                      icon={faSpinner}
                    />
                  )}
                </h4>
                <button
                  onClick={this.addNewAddres}
                  type="button"
                  className={"cbtn"}
                >
                  Ajouter
                </button>
              </div>

              <form>
                {this.state.addresses.map((address, idx) => (
                  <ClientAddressRow
                    deleteAddress={this.deleteAddress}
                    saveAddress={this.saveAddress}
                    updateAddressField={this.updateAddressField}
                    address={address}
                    key={address.address_id + "_" + idx}
                    idx={idx}
                  />
                ))}
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatch = dispatch => {
  return {
    isLoading: m => dispatch(startLoading(m)),
    doneLoading: m => dispatch(doneLoading())
  };
};
export default connect(null, mapDispatch)(ClientDetail);
