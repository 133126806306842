import React from "react"
import { Link } from "react-router-dom"

export default class AgentActivity extends React.Component {

    render() {
        return <div>
            <div className={"col-md-6"}>
  				<h4>Recent activity</h4>

  				{this.props.activity.map((v, i) =>(

  				<div className={"row act-row"}>
  					<div className={"col-md-4"}>
  						{v.datetime}
  					</div>
  					<div className={"col-md-4"}>
  						{v.action} 
  					</div>
					  <div className={"col-md-4"}>
						<Link 
							to={{
								pathname:'/task/'+v.task.task_id,
							}}
						>
						<abbr title="cliquez pour plus de détails">{v.task.task_name}</abbr>
  						
						</Link>
  					</div>
  				</div>
  				))}


  			</div>

        </div>
    }
}