import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import AgentSettingsDetail from './../Components/Admin/AgentSettingsDetail';
import StatusSettings from './../Components/Admin/StatusSettings';




function mapStateToProps(state) {
  return { auth: state.auth };
};


class ItemRouter extends Component {

	
	render() {
		return(
				<Switch>

					{(!this.props.auth ? <Redirect to="/login" /> : "")}
						<Route exact path='/settings/status' component={StatusSettings} />
						<Route exact path='/settings/agent/:userid' component={AgentSettingsDetail} />
							

						
				</Switch>


			
		)
	}
}

export default connect(mapStateToProps)(ItemRouter)