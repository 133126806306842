import store from "../Redux/store";
import { loadData } from "../Redux/actions";
import api from "./Api";

export function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const jsonFromRef = ref => {
  if (!ref) return false;

  const formdata = new FormData(ref);

  let data = {};

  for (var [key, value] of formdata.entries()) data[key] = value;

  return data;
};

export const loadCommonData = () => {
  const loadVehicules = api.get("/api/v2/vehicule/fetch");
  const loadTaskStatus = api.get("/api/v2/status/fetch");

  Promise.all([loadVehicules, loadTaskStatus]).then(response => {
    if (response[0].data.success)
      store.dispatch(
        loadData({
          vehicules: response[0].data.data,
          taskStatus: response[1].data.data
        })
      );
  });
};

export const buildUriParams = (filters) => {
    let result = "";
    for(let i in filters){
      console.log(i);
    }
    Object.keys(filters).forEach(key => {
        if(filters[key]){
          if(typeof(filters[key]) == "object"){
            Object.keys(filters[key]).forEach(cle => {
              result = `${result}&${key}=${filters[key][cle]}`
            })
          }else{
            result = `${result}&${key}=${filters[key]}`
          }
          
        }
            
    })

    return result;

}