import React, { Component } from 'react';
import {  NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation, Trans } from "react-i18next";

const mapStateToProps = state => {
	return {
		auth: state.auth,
		user: state.user
	}
}

class CommercialSideMenu extends Component {

	constructor(props) {
		super(props);


	}

	componentDidMount() {
		
	}

	render() {

		if(this.props.auth)
		return (
			
			<ul className={"sideMenu-list"}>
				<li> <NavLink to={"/task"}><Trans i18nKey="label_tasks" /></NavLink>  </li>
				<li> <NavLink to={"/client"}>Clients</NavLink>  </li>
				<li> <NavLink to={"/account"}><Trans i18nKey="menu_account" /></NavLink>  </li>
				<li> <NavLink to={"/logout"}><Trans i18nKey="logout" /></NavLink>  </li>

			</ul>

		)
	}
}



export default connect(mapStateToProps, null, null, { pure: false})(CommercialSideMenu)