import React from 'react';
import  * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Button } from 'bootstrap';
import { Trans } from 'react-i18next';

export const ExportCSV = ({csvData, fileName}) =>{
    
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    // const csvData1 = csvData;
    // const fileName1 = fileName

    const exportToCSV = () => {
        
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    return(
        <button className={"btn btn-md btn-success px-4"} onClick={(e) => exportToCSV()}><Trans i18nKey="button_export"/></button>
    )
}
