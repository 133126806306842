import React from "react";
import api from "../../Utils/Api";
import { jsonFromRef } from "../../Utils/Helpers";
import { connect } from "react-redux";
import { startLoading, doneLoading, setAuthAction } from "../../Redux/actions";




class ClientNew extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            app_id: "sodMSfpEnNEZfHC7oV0M",
			app_code: "noVfKVAMsfkjYV3Cv6jmnA",
			useHTTPS: true,
            center: {
                lat: 36.75,
                lng: 3.04,
			},
			zoom: 5,
			address: "",
			last_use: "component",
        }

    }

    componentDidMount() {
        this.platform = new window.H.service.Platform(this.state);
		var layer = this.platform.createDefaultLayers();
		var container = document.querySelector('#here-maps');
	
		this.map = new window.H.Map(container, layer.normal.map, {
			center: this.state.center,
			zoom: this.state.zoom,
		});
		
		var events = new window.H.mapevents.MapEvents(this.map);
		new window.H.mapevents.Behavior(events);
        new window.H.ui.UI.createDefault(this.map, layer)
        
    }

    submitNewClient = (e) => {
        e.preventDefault();

        this.props.isLoading();

        
        api.post('/api/client/new', jsonFromRef(e.target))
        .then(response => {
            if(response.data.success)
                this.props.history.push('/task');

            this.props.doneLoading();
        })
    }

    render() {

        return (<>


            <div className={"container-header"}>
				Nouveau Client
			</div>


            <div className={"row"}>

            

                <div className={"col-md-6 np"}>

                    <div className={" ui-rect np"}>
                        <form onSubmit={this.submitNewClient} >
                            <div className={"form-group"}>
                                <label>Nom</label>
                                <input name="client_name" type={"text"} className={"form-control"} />
                            </div>

                            <div className={"form-group"}>
                                <label>Address</label>
                                <input name="client_address" type={"text"} className={"form-control"} />
                            </div>

                            <div className={"form-group"}>
                                <label>Téléphone</label>
                                <input name="client_contact" type={"text"} className={"form-control"} />
                            </div>

                            <div className={"form-group"}>
                                <button className={"btn btn-success"}>Ajouter</button>
                            </div>

                        </form>
                    </div>

                </div>

                <div className={"col-md-6"}>
                    <div className={" ui-rect"}>
                       <div className={"map-container "} style={{height: "400px", borderColor: '#ccc', borderWidth: "1px", borderRadius: "2px", borderStyle: "solid"}} id="here-maps"></div>
                    </div>
                   
                </div>
            </div>


        </>)
    }
}

const mapDispatchToProps = (dispatch) => 
	({
		isLoading: e => dispatch(startLoading(e)),
		doneLoading: e => dispatch(doneLoading()),
		setAuth: e => dispatch(setAuthAction(e))
    })
    
export default connect(null, mapDispatchToProps)(ClientNew);