import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentAlt, faUser } from "@fortawesome/free-solid-svg-icons";
import { Trans } from "react-i18next";
import api from "../../../Utils/Api";
import ClientInput from "./ClientInput";
import { connect } from "react-redux";
import { startLoading, doneLoading, setOverlay, setFlash } from "../../../Redux/actions";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import { loadCommonData } from "../../../Utils/Helpers";

const vehiculeOptions = [
  { label: "20T", value: "20T" },
  { label: "20T MRCH", value: "20T MRCH" },
  { label: "20T TC", value: "20T TC" },
  { label: "40T", value: "40T" },
  { label: "10T", value: "10T" },
  { label: "10T MRCH", value: "10T MRCH" },
  { label: "CAMION 2T5", value: "CAMION 2T5" },
  { label: "CAMION 3T5", value: "CAMION 3T5" },
  { label: "CAMION 4T", value: "CAMION 4T" },
  { label: "Camion 4T5", value: "Camion 4T5" },
  { label: "CAMION 5T", value: "CAMION 5T" },
  { label: "CAMION FRIGO", value: "CAMION FRIGO" },
  { label: "CHARIOT ELEVATEUR", value: "CHARIOT ELEVATEUR" },
  { label: "DEPANNAGE", value: "DEPANNAGE" },
  { label: "FOURGON 1T5 CHL", value: "FOURGON 1T5 CHL" },
  { label: "FOURGON 1T5", value: "FOURGON 1T5" },
  { label: "FOURGON 2T5", value: "FOURGON 2T5" },
  { label: "FOURGON 3T5", value: "FOURGON 3T5" },
  { label: "FOURGON 3T5 HAYON", value: "FOURGON 3T5 HAYON" },
  { label: "FOURGON CONTENEUR", value: "FOURGON CONTENEUR" },
  { label: "FOURGONNETTE", value: "FOURGONNETTE" },
  { label: "GROUPAGE", value: "GROUPAGE" },
  { label: "GRUE", value: "GRUE" },
  { label: "PORTE CHAR", value: "PORTE CHAR" },
  { label: "TRAFIC", value: "TRAFIC" },
];

class TaskForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: "",
      clientsList: [],
      client: {
        client_name: "",
        client_id: "",
        client_address: "",
      },
    };

    this.clientInputRef = null;

    this.taskForm = null;
  }

  submitTask = (e) => {
    e.preventDefault();
    this.setState({error: ""});
    const formData = new FormData(this.taskForm);

    let data = {};

    
    for (var [key, value] of formData.entries()) data[key] = value;
    
    if(!data['subtask[endTask][address_commune]'] || !data['subtask[startTask][address_commune]'] || !data['task_vehicule_type']) {
      this.setState({error: "Veuillez remplir les champs obligatoire"})
      return false;
    }

    this.props.isLoading({
      message: "Ajout en cours",
      animate: true,
    });


    api
      .post("/api/v2/task/new", formData)
      .then((res) => {
        // console.log(res, this.props)
        // return;

        this.props.doneLoading();

        console.log(this.props.inline && this.props.afterPost);
        if (res.data.success) {
          if (this.props.inline && this.props.afterPost) {
            this.props.afterPost();
          } else {
            this.props.history.push("/task");
          }

          this.props.history.push("/task");
        }
      })
      .catch((err) => {
        this.props.doneLoading();
      });

    //console.log(data);

    return false;
  };

  componentDidMount() {
    loadCommonData();
  }

  render() {
    const selectedVehicule = {
      label: this.props.data.task_vehicule_type,
      value: this.props.data.task_vehicule_type_id,
    };

    console.log(this.props.client);
    
    return (
      <>
        {Boolean(this.state.error) && <span className={"alert alert-warning d-block"}>{this.state.error}</span>}
        <form ref={(ref) => (this.taskForm = ref)} onSubmit={this.submitTask}>
          {!this.props.isDuplicate && <input type="hidden" name="task_id" value={this.props.data.task_id} />}

          <div className={"row"}>
            <div className={"col-md-6 taskForm"}>
              <div className={"ui-rect np mb-3"}>
                <div className={"row"}>
                  <div className={"form-group col-md-4"}>
                    <label>N° BL Client</label>
                    <input
                      defaultValue={this.props.data.task_bl_client}
                      type="text"
                      name={`bl_client`}
                      className={"form-control"}
                    />
                  </div>

                  <div
                    className={"form-group geo-wrapper col-md-4"}
                    style={{ position: "relative" }}
                  >
                    <label>
                      <b>Client *</b>
                    </label>

                    <ClientInput
                      defaultValue={this.props.data.task_client_name}
                      selectClient={this.props.handlers.selectClient}
                      type="text"
                      name="task_client"
                      className={"form-control"}
                    />

                    <input
                      type="hidden"
                      name="task_client_id"
                      value={this.props.client.client_id || ""}
                    />
                  </div>

                  <div className={"form-group col-md-4"}>
                    <label>Mise à dispo</label>
                    <Select
                      defaultValue={this.props.miseDispo.filter(
                        (item) => item.value === this.props.data.task_mise_dispo
                      )}
                      classNamePrefix={"custom-select"}
                      name="mise_dispo"
                      defaultValue={{ label: "N/A", value: "na" }}
                      options={this.props.miseDispo}
                    />
                  </div>
                </div>

                <div className={"row"}>
                  <div className={"form-group col-md-4"}>
                    <label>Type course</label>

                    <Select
                      defaultValue={this.props.typeCourse.filter(
                        (item) =>
                          item.value === this.props.data.task_type_course
                      )}
                      classNamePrefix={"custom-select"}
                      name="type_course"
                      options={this.props.typeCourse}
                    />
                  </div>

                  <div className={"form-group col-md-4"}>
                    <label>
                      <b>Type véhicule *</b>
                    </label>

                    <Select
                      defaultValue={selectedVehicule}
                      classNamePrefix={"custom-select"}
                      name="task_vehicule_type"
                      options={this.props.vehicules}
                    />
                  </div>
                  <div className={"form-group col-md-4"}>
                    <label>Status</label>
                    <Select
                      defaultValue={this.props.task_status.filter(item => item.value === this.props.data.status)}
                      classNamePrefix={"custom-select"}
                      name="task_status"
                      options={this.props.task_status_to_create}
                    />
                  </div>
                </div>

                <div className={"row"}>
                  <div className={"form-group col-md-6"}>
                    <label>Réglement</label>

                    <Select
                      defaultValue={this.props.typeReglement.filter(
                        (item) =>
                          item.value === this.props.data.task_type_reglement
                      )}
                      classNamePrefix={"custom-select"}
                      name="type_reglement"
                      options={this.props.typeReglement}
                    />
                  </div>

                  <div className={"col-md-6 form-group"}>
                    <label>Info facture</label>
                    <input
                      defaultValue={this.props.data.task_info_facture}
                      type={"text"}
                      name={"info_facture"}
                      className={"form-control"}
                    />
                  </div>
                </div>
                <div className={"row"}>
                  <div className={"col-md-6 form-group"}>
                    <label>Achat</label>
                    <input
                      defaultValue={this.props.data.task_tarif_achat}
                      type={"text"}
                      name={"tarif_achat"}
                      className={"form-control"}
                    />
                  </div>

                  <div className={"col-md-6 form-group"}>
                    <label>Vente</label>
                    <input
                      defaultValue={this.props.data.task_tarif_vente}
                      type={"text"}
                      name={"tarif_vente"}
                      className={"form-control"}
                    />
                  </div>
                </div>

                <div className={"row"}>
                  <div className={"form-group col-md-12"}>
                    <label>Observation vente</label>
                    <textarea
                      defaultValue={this.props.data.task_obs_vente}
                      type="text"
                      className={"form-control"}
                      name="obs_vente"
                    />
                  </div>
                  <div className={"form-group col-md-12"}>
                    <label>Instruction</label>
                    <textarea
                      defaultValue={this.props.data.task_instruction}
                      type="text"
                      className={"form-control"}
                      name="instruction"
                    />
                  </div>
                </div>
              </div>

              <div className={"ui-rect np"}>
                <div className={"row"}>
                  <div className={"col-md-6"}>
                    <h5>Documents</h5>
                  </div>

                  {this.props.data.task_docs_recu && (
                    <div className={"col-md-6 text-right"}>
                      Docuements Reçu
                      <input
                        type="checkbox"
                        disabled
                        checked
                        className={"custom-checkbox ml-3"}
                      />
                    </div>
                  )}
                </div>

                <div className={"form-group mt-1"}>
                  <label>Commentaire</label>

                  <textarea
                    defaultValue={this.props.data.task_docs_comment}
                    type="text"
                    className={"form-control"}
                    name="task_docs_comment"
                  />
                </div>
              </div>

              <div className={"ui-rect np"}>
                <div className={"row"}>
                    <div className={"form-group col-md-4"}>
                      <label>Immobilisation signalée</label>
                      <input type="checkbox" value="true" name={"task_immo"} defaultChecked={parseInt(this.props.data.task_immo)} />
                    </div>
                    <div className={"form-group col-md-4"}>
                      <label>Retour</label>
                      <input type="checkbox" value="true" name={"task_retour"} defaultChecked={parseInt(this.props.data.task_retour)} />
                    </div>
                    <div className={"form-group col-md-4"}>
                      <label>KM Sup.</label>
                      <input type="checkbox" value="true" name={"task_km_sup"} defaultChecked={parseInt(this.props.data.task_km_sup)} />
                    </div>
                </div>
              </div>

              <div className={"form-group"}>
                <button type="submit" className={"cbtn large block success"}>
                  Sauvegarder
                </button>
              </div>
            </div>

            <div className={"col-md-6 taskForm"}>
              {this.props.children}

              <div
                className={
                  "d-none ui-rect map-container " +
                  (this.props.pinOnMap && "show")
                }
                style={{
                  height: "400px",
                  borderColor: "#ccc",
                  borderWidth: "1px",
                  borderRadius: "2px",
                  borderStyle: "solid",
                }}
                id="here-maps"
              />
            </div>
          </div>
        </form>
      </>
    );
  }
}

const mapDispatch = (dispatch) => {
  return {
    isLoading: (conf) => dispatch(startLoading(conf)),
    doneLoading: () => dispatch(doneLoading()),
    setOverlay: e => dispatch(setOverlay(e)),
    setFlash: f => dispatch(setFlash(f))
  };
};

const mapState = (state) => {
  return {
    vehicules: state.vehicules,
    miseDispo: state.miseDispo,
    typeCourse: state.typeCourse,
    typeReglement: state.typeReglement,
    task_status: state.task_status,
    task_status_to_create : state.task_status_to_create
  };
};

export default withRouter(
  connect(
    mapState,
    mapDispatch
  )(TaskForm)
);
