import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setAuthAction, startLoading, doneLoading } from '../../Redux/actions/index';
import api from '../../Utils/Api';
import Select from "react-select";
import { Link, Redirect } from 'react-router-dom';



const mapStateToProps = state => {
	return {
        user: state.user,
        auth: state.auth
	}
}

const mapDispatchToProps = dispatch => {
	return {
		isLoading: e => dispatch(startLoading(e)),
        doneLoading: () => dispatch(doneLoading()),
        setAuth: e => dispatch(setAuthAction(e))
	}
}


class NewPlan extends Component {


    constructor(props) {
        super(props);

        this.state = {
            step: 1,
            agents: 0,
            credits: 0
       };


        this.selectPlan = this.selectPlan.bind(this);
        this.updateInput = this.updateInput.bind(this)
        this.nextStep = this.nextStep.bind(this);

    }

    updateInput(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    selectPlan(plan) {
    
        this.setState({
            selectedPlan: plan
        })
    
    }

    componentDidMount() {

        const that = this;

    }

    toggleStart(e) {

        this.setState({
            autostart: e.target.checked
        })

    }

    nextStep(s) {
        console.log(this.state)
        this.setState({
            step: s
        })
    }

    

   

    

    render() {
        return (<React.Fragment>

            <div className={"container-header nmb"}>
            Customize your plan
            </div>

            {this.state.step === 1 && (
                <React.Fragment>

                <div className={"row my-5"}>
                
                <div className={"col-md-3 offset-md-3 customPlan "+ (this.state.selectedPlan === 1 && "active")} onClick={e => this.selectPlan(1)}>
                    <Link to={"/manager/fill"}>
                            <h5>Prepaid</h5>
                            <ul className={"plan-features"}>
                                <li>Deposit</li>
                                <li>25 DZD per task</li>
                                <li>Aze</li>
                            </ul>
                    </Link>
                </div>

                <div className={"col-md-3 ml-2 customPlan " + (this.state.selectedPlan === 2 && "active")} onClick={e => this.selectPlan(2)}>
                    <h5>Agent Subscription</h5>

                    <ul className={"plan-features"}>
                        <li>Pay per agent</li>
                        <li>20 task per agent</li>
                        <li>Aze</li>
                    </ul>
                    <br />
                    {this.props.user.plan_type === "free" && (<p>You're still in free trial. You will be charged one you exceed plan limits.</p>)}

                </div>


                </div>

                {this.state.selectedPlan && (
    
                <div>
                
                {this.state.selectedPlan === 2 && (<Redirect to="/manager/agents/new" />)}
                    
               
    
    
    
                {this.state.selectedPlan === 1 && (<Redirect to="/manager/fill" />)}
                    
                
                </div>
    
                )}


                </React.Fragment>
            )}

            {this.state.step === 2 && (
                <React.Fragment>
                    <button className={"btn btn-primary mt-4"} onClick={e => this.nextStep(1)}>Back</button>

                    <div className={"clear mb-3"}></div>

                    <h3>
                        Resumé
                    </h3>

                    <div className={"table-responsive"}>
                    <table className={"table"}>
                        <tr>
                            <td>Article</td>
                            <td>Price</td>
                            <td>Quantity</td>
                            <td>Total</td>
                        </tr>
                        <tr>
                            <td>
                            {this.state.selectedPlan === 1
                            ? "Credit"
                            : "Agent"}
                            </td>
                            <td>
                            {this.state.selectedPlan === 1
                            ? 1
                            : 2000}
                            </td>
                            <td>
                            {this.state.selectedPlan === 1
                            ? this.state.credits
                            : this.state.agents}
                            </td>
                            <td>
                            {this.state.selectedPlan === 1
                            ? this.state.credits
                            : this.state.agents*2000}
                            </td>
                        </tr>
                        

                    </table>
                    </div>
                    
                    <form action="/payment/1" method="get">

                        <button
                        type="submit"
                        className={"btn btn-success float-right"}>Proceed to payment</button>
                    </form>

                
                </React.Fragment>
            )}

            


            
        </React.Fragment>)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewPlan)