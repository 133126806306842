import React, {Component} from 'react';

class RapportRow extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <React.Fragment>
                {/* this div for data */}
                <div>
                    <div className="col-md ">
                        {/* ------- to display row-------- */}
                        <div className="row table-row my-4 ">
                            <td><div className="col-md">hello</div></td>
                            <td><div className="col-md">hello</div></td>
                            <td><div className="col-md">hello</div></td>
                            <td><div className="col-md">hello</div></td>
                            <td><div className="col-md">hello</div></td>
                            <td><div className="col-md">hello</div></td>
                            <td><div className="col-md">hello</div></td>
                            <td><div className="col-md">hello</div></td>
                            <td><div className="col-md">hello</div></td>
                            <td><div className="col-md">hello</div></td>
                            <td><div className="col-md">hello</div></td>
                            <td><div className="col-md">hello</div></td>
                            <td><div className="col-md">hello</div></td>
                            <td><div className="col-md">hello</div></td>
                            <td><div className="col-md">hello</div></td>
                            <td><div className="col-md">hello</div></td>
                            <td><div className="col-md">hello</div></td>
                            <td><div className="col-md">hello</div></td>
                            <td><div className="col-md">hello</div></td>
                            <td><div className="col-md">hello</div></td>
                            <td><div className="col-md">hello</div></td>
                            <td><div className="col-md">hello</div></td>
                            <td><div className="col-md">hello</div></td>
                            <td><div className="col-md">hello</div></td>
                            <td><div className="col-md">hello</div></td>
                        </div>
                    </div>
                </div>
            </React.Fragment>
         );
    }
}
 
export default RapportRow;