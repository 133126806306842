import React, { Component } from 'react';
import { Route, Redirect } from 'react-router'

export default class HomeView extends Component {
	render() {
		return(
			<Redirect to={"/login"} /> 
		)
	}
}

