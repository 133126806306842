import React from "react";
import { Link } from "react-router-dom";
import api from "../../Utils/Api";
import {
  faSpinner,
  faInfo,
  faTrash,
  faPen
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { startLoading, doneLoading, setAuthAction } from "../../Redux/actions";
import Select from "react-select";
import Pagination from "../../Utils/Pagination";
import {ExportCSV}  from '../Rapport/ExportCSV';

class ClientList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clients: [],
      total: 0,
      current: 1,
      pages: 1,
      limit: 10,
      filters: {
        client: ""
      }
    };

    this.props.isLoading();
  }

  componentDidMount() {
    this.loadClients();
  }

  buildURI = () => {
    const { filters } = this.state;
    return `/api/v2/client/fetch?client=${filters.client}&page=${this.state.current}&limit=${this.state.limit}`;
  };

  loadClients = (p = this.state.current) => {
    api
      .get(this.buildURI())
      .then(res => {
        if (res.data.success) this.setState({ clients: res.data.clients, total: res.data.total, pages: res.data.pages });

        this.props.doneLoading();
      });
  };

  changeLimit = e => {
    this.props.isLoading();
    this.setState(
      {
        limit: e.value,
        current: 1
      },
      () => this.loadClients()
    );
  };

  selectPage = p => {
    this.props.isLoading();

    this.setState({ current: p }, () => this.loadClients(p));

    
  };

  updateFilter(e, name) {
    let that = this;

    const value = e.target ? e.target.value : e.value;

    this.setState(
      {
        filters: {
          ...this.state.filters,
          [name]: value
        }
      },
      function() {
        that.props.isLoading();
        that.loadClients(1);
      }
    );
  }
  
  signal = (event, id) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    console.log('check box => '+ value)
    console.log('hello id => '+ id)
    this.props.isLoading();
    api.post("/api/client/signal/"+id).then((response) => {
      if (response.data.success) {
        this.loadClients(1);
      } else {
       
      }

      this.props.doneLoading();
      // console.log(this.state.tasks);
    });
    // if(value){
    //   var apply = this.state.remis - ( (this.state.remisWithoutApply * 1.5) / 100)
    // }else{
    //   var apply =  this.state.remisWithoutApply
    // }
    

    // this.setState({
    //   remis: apply,
    //   checked: value
    // })

  }

  render() {
    var today = new Date(),
    now = '_' + today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    return (
      <>
        <div className={"container-header"}>
          Clients
          {!!this.props.user &&
            (this.props.user.role === "manager" ||
              this.props.user.role === "admin" ||
              this.props.user.role === "commercial") && (
              <Link to={"/client/new"} className={"cbtn float-right"}>
                Nouveau Client
              </Link>
            )}
        </div>

        <div className={"row form ui-rect"}>
          <div className={"col-md-3"}>
            <label>Recherche</label>
            <input
              type={"text"}
              name="client"
              onChange={e => this.updateFilter(e, "client")}
              className={"form-control"}
            />
          </div>
          <div class="col-md-9 text-right">
           <ExportCSV csvData={this.state.clients} fileName={'Clients' + now} classBtn = {'btn btn-md mt-4 btn-outline-success '}/>
          </div>
        </div>

        <div className={"row ui-rect my-4"}>
          <div className={"col-md-6 items-found"}>
            <p>
              <b>{this.state.total}</b> clients trouvés
            </p>
          </div>

          <div className={"col-md-6 per-page-wrap"}>
            <div className={"per-page-content"}>
              <p>Résultat par page</p>
              <Select
                classNamePrefix={"custom-select"}
                onChange={this.changeLimit}
                className={"per-page nm"}
                options={this.props.filters.result_per_page}
                value={{ label: this.state.limit, value: this.state.limit }}
              />
            </div>
            <Pagination data={this.state} handleClick={this.selectPage} />
          </div>
        </div>

        <div className="ctable mb-3  ui-rect np">
          <div className={"row head-titles ctable-row task-row"}>
            <div className={"col-md-1 "}>  ID</div>

            <div className={"col-md-3"}>Client</div>

            <div className={"col-md-2"}>Adresse principale</div>

            <div className={"col-md-2"}>Téléphone</div>

            <div className={"col-md-2"}>Email</div>
            <div className={"col-md-2 action-col"}>Actions</div>
          </div>

          {this.state.clients.map((client, idx) => (
            <div key={idx} className={"row ctable-row"}>
              
              <div className={"col-md-1"}>                
                {client.client_ispayment == 'no' ? <input
                  name="signal"
                  type="checkbox" 
                  className="mr-2"
                  checked
                  onChange={e => this.signal(e, client.client_id)}
                /> :
                <input
                  name="signal"
                  type="checkbox" 
                  className="mr-2"
                  onChange={e => this.signal(e, client.client_id)}
                />
                }
                {client.client_id}</div>

              <div className={"col-md-3"}>{client.client_name}</div>

              <div className={"col-md-2"}>{client.client_address}</div>

              <div className={"col-md-2"}>{client.client_contact}</div>

              <div className={"col-md-2"}>{client.client_contact}</div>
              <div className={"col-md-2 action-col"}>
                <Link to={"/client/detail/"+client.client_id} className={"custom-action-btn"}>
                  <FontAwesomeIcon icon={faPen} /> Modifier
                </Link>
                <button className={"custom-action-btn danger"}>
                  <FontAwesomeIcon icon={faTrash} /> Supprimer
                </button>

                
              </div>
            </div>
          ))}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    user: state.user,
    filters: state.filters
  };
};

const mapDispatchToProps = dispatch => ({
  isLoading: e => dispatch(startLoading(e)),
  doneLoading: e => dispatch(doneLoading()),
  setAuth: e => dispatch(setAuthAction(e))
});
export default connect(mapStateToProps, mapDispatchToProps)(ClientList);
