import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import LoginBox from './Login/LoginBox';
import HomeView from './Home/HomeView';
import UserRouter from './../Routers/UserRouter';
import TaskRouter from './../Routers/TaskRouter';
import SettingsRouter from './../Routers/SettingsRouter';
import ManagerRouter from './../Routers/ManagerRouter';
import AdminRouter from './../Routers/AdminRouter';
import UserAccount from './User/UserAccount';
import TaskListContainer from '../Containers/TaskListContainer';
import Register from './../Components/User/Register';
import NewPlan from './User/NewPlan';
import ClientList from './Client/ClientList';
import ClientNew from './Client/ClientNew';
import ClientDetail from './Client/ClientDetail';
import RapportRouter from '../Routers/RapportRouter';


const mapStateToProps = state => {
	return {
		auth: state.auth,
		user: state.user
	}
}
class AppRouter extends Component {

	render() {
		return(
			<div>
				<Switch>
					<Route exact path='/login' component={LoginBox} />
		      	  	<Route exact path='/' component={HomeView} />

		      	  	<Route path='/manager' component={ManagerRouter} />
					<Route path='/settings' component={SettingsRouter} />
					<Route path='/users' component={UserRouter} />

					<Route exact path='/client' component={ClientList} />
					<Route exact path='/client/new' component={ClientNew} />
					<Route exact path='/client/detail/:id' component={ClientDetail} />

					<Route path='/account' component={UserAccount} />
					<Route path='/plan' component={NewPlan} />
					<Route path='/register' component={Register} />

					<Route path='/agent/tasks' component={TaskListContainer} />

					

					<Route path='/task' component={TaskRouter} />

					<Route path='/rapport' component={RapportRouter} /> 

					
					<Route path='/payments' component={AdminRouter} />
					<Route path='/bill' component={AdminRouter} />

					

				</Switch>
			</div>

			
		)
	}
}


export default withRouter(connect(mapStateToProps)(AppRouter));