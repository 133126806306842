import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import SideMenu from './Menus/SideMenu';
import AccountSideMenu from './Menus/AccountSideMenu';
import UserSideMenu from './Menus/UserSideMenu';

import FlashMessage from './../Utils/flashMessage.js'


 class Body extends Component {

	
	render() {
		
		return (
			<div className="fluid-container">



				<div className="col-md-12 mainContent">

					<FlashMessage />

					<div className={"bg-container"}>
						{this.props.children}
					</div>
				</div>

						

					


			</div>
			
		);
	}
}

export default Body;