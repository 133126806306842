import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { Switch, Route, Redirect, Link } from 'react-router-dom';
import { setAuthAction, startLoading, doneLoading } from './../../Redux/actions/index';
import api from '../../Utils/Api';
import Select from 'react-select';

let REMOTE_URL = process.env.REACT_APP_REMOTE_URL ? process.env.REACT_APP_REMOTE_URL : 'https://salim.tictechs.tech';



function mapStateToProps(state) {
  return { 
  	auth: state.auth,
  	user: state.user
  };
};


function mapDispatchToProps(dispatch) {
  return {
    setAuth: user => dispatch(setAuthAction(user)),
    isLoading: e => dispatch(startLoading(e)),
    doneLoading: e => dispatch(doneLoading(e)),
  };
}


 class PayCCP extends Component {

 	constructor(props) {
		 super(props);
		
 		this.state = {

		}

		this.submitForm = this.submitForm.bind(this)

 	}


 	componentDidMount() {

		 
	 }
	
	submitForm(e) {
		e.preventDefault();

		// let form = new FormData(e.target);

		// let data = {}; 

		// for(let [key, value] of form.entries()) {
		// 	data[key] = value;
		// }


	}

 	
	render() {

		return(
			<React.Fragment>
			

						<form encType="multipart/form-data" action={REMOTE_URL+"/api/user/"+this.props.user.userid+'/fill'} method="post" >
						
						<input type="hidden" value={this.props.user.token} name="token" />
						<input type="hidden" value={this.props.amount} name="amount" />
						<input type="hidden" value={this.props.method} name="method" />

						<div className={"form-group"}>
							<label>Nom :</label>
							<input name="last_name" type="text" className={"form-control col-md-5"} /> 
						</div>

						<div className={"form-group"}>
							<label>Prénom :</label>
							<input name="first_name" type="text" className={"form-control col-md-5"} /> 
						</div>

						<div className={"form-group"}>
							<label>Raison Social :</label>
							<input name="raison" type="text" className={"form-control col-md-5"} /> 
						</div>

						<div className={"form-group"}>
							<label>Adresse :</label>
							<input name="address" type="text" className={"form-control col-md-5"} /> 
						</div>

						<div className={"form-group"}>
							<label>Numéro de téléphone :</label>
							<input name="phone" type="text" className={"form-control col-md-5"} /> 
						</div>

						<div className={"form-group"}>
							<label>Reçu de virement :</label>
							<input name="image" type="file" className={"form-control col-md-5"} /> 
						</div>



					<br />
					
					<button className={"btn btn-primary float-right"}>Continuer</button>

					</form>


			</React.Fragment>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PayCCP);