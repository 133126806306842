import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronRight, faList } from "@fortawesome/free-solid-svg-icons";



export default class MoreInfoDialog extends React.Component {

    constructor() {
        super();

        this.state = {
            open: false
        }
    }

    hide = () => this.setState({ open: false })

    toggle = (e) => { console.log('toggle'); this.setState({ open: !this.state.open }); e.stopPropagation();  }

    componentDidMount() {
        document.body.addEventListener("click", this.hide)
    }

    componentWillMount() {
        document.body.removeEventListener("click", this.hide)
    }

    render() {


        return (
            <span className={"more-dialog" +(this.state.open ? " open": "")}>
                <button onClick={this.toggle} className="btn-block btn btn-sm btn-primary" >Actions</button>
                <ul>
                    {this.props.children}
                </ul>
            </span>
        )
    }
}


export const Item = (props) => (
    <li onClick={props.onClick}>{props.children}</li>
)