import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, Redirect, Route,  Switch} from 'react-router-dom';

import { setAuthAction, startLoading, doneLoading } from './../Redux/actions/index';
import api from '../Utils/Api';

import AgentTasks from './../Components/Admin/AgentTasks';
import AgentDetail from './../Components/Admin/AgentDetail';
import AgentActivity from './../Components/Admin/AgentActivity';
import AgentGeo from './../Components/Admin/AgentGeo';
import AgentOverview from './../Components/Admin/AgentOverview';
import { Trans } from "react-i18next";

const mapStateToProps = state => {
	return {
		auth: state.auth,
		user: state.user
	}
}

const mapDispatchToProps = dispatch => {
	return {
		setAuth: user => dispatch(setAuthAction(user)),
		isLoading: e => dispatch(startLoading(e)),
		doneLoading: () => dispatch(doneLoading())
	}
}


class AgentContainer extends Component {

    constructor(props){
        super(props)

        this.state = {
            ready: false,
			info: {
				userid: 0,
				username: "",
				password: "",
				firstName: "",
				lastName: "",
				email: "",
				phone: "",
				activity: []
			}
		}

        this.props.isLoading();
        
    }


	componentDidMount() {
        
		let that = this;

        
		api.get('/api/user/'+this.props.match.params.userid+'?tab=personal&token='+this.props.user.token)
		.then(function(response) {

			if(!response.data.success) {
				that.props.doneLoading();
				that.props.setAuth(response.data);
				return true;
			}

            that.setState({info: response.data, ready: true})
			

			that.props.doneLoading();
			
		});


	}

	render() {

		return(
			<div>

			{!this.props.auth ? <Redirect to="/login" /> :  ""}

			<div className={"container-header nmb"}>
				<Trans i18nKey="agent_details" /> 

			</div>


			<div className={"ui-rect np"}>
				
				<div className={"page-menu"}>
					<ul>
						<li><NavLink strict exact to={"/manager/agents/"+this.props.match.params.userid}><Trans i18nKey="account_overview" /></NavLink></li>
						<li><NavLink strict exact to={"/manager/agents/"+this.props.match.params.userid+"/account"}><Trans i18nKey="account_account" /></NavLink></li>
						{/* <li><NavLink strict exact to={"/manager/agents/"+this.props.match.params.userid+"/tasks"}><Trans i18nKey="label_tasks" /></NavLink></li> */}
						<li><NavLink strict exact to={"/manager/agents/"+this.props.match.params.userid+"/activity"}><Trans i18nKey="label_activity" /></NavLink></li>
						<li><NavLink strict exact to={"/manager/agents/"+this.props.match.params.userid+"/track"}><Trans i18nKey="account_track" /></NavLink></li>
					</ul>
				</div>

				<div className={"row"}>

					<div className={"col-md-12"}>
					

					<Switch>


						<Route 
						path='/manager/agents/:userid/account'
						exact 
						component={AgentDetail} />


						<Route 
						path='/manager/agents/:userid/activity'
						exact 
						component={() => <AgentActivity activity={this.state.info.activity} />} />

						<Route 
						path='/manager/agents/:userid/tasks' 
						exact 
						component={AgentTasks} />

						<Route 
						path='/manager/agents/:userid/track' 
						exact 
						component={AgentGeo} />

						<Route 
						path='/manager/agents/:userid' 
						exact
						component={AgentOverview} />

						
						
						
						
					</Switch>

					</div>  
				</div>

			</div>

			
			</div>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AgentContainer)