import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import _ from 'lodash';
import { startLoading, doneLoading } from '../../Redux/actions/index';



const mapStateToProps = state => {
	return {
		user: state.user
	}
}

const mapDispatchToProps = dispatch => {
	return {
		isLoading: e => dispatch(startLoading(e)),
		doneLoading: () => dispatch(doneLoading())
	}
}

const axios = require("axios");

class AgentSettingsDetail extends Component {


	constructor(props) {
		super(props);
		this.state = {
			locations:[],
			form: {
				locations: []
			},
			info: {
				userid: 0,
				username: "",
				firstName: "",
				lastName: "",
				email: "",
				authorization: 
				{					
						manage_location: []

				}
			}
		}

		this.props.isLoading();

		this.handelLocationChange = this.handelLocationChange.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.handleCheckbox = this.handleCheckbox.bind(this);
		this.formSubmit = this.formSubmit.bind(this);
	}


	handleCheckbox(e) {

		let nstate = this.state;
		const key = e.target.getAttribute('data-loc-key');
		
		if(e.target.checked) {
			//_.unset(nstate, 'info.authorization.manage_location['+key+'].authorization['+e.target.name+']');
			if(nstate.info.authorization.manage_location[key].authorization.indexOf(e.target.name) === -1)
				nstate.info.authorization.manage_location[key].authorization.push(e.target.name);
		} else {
				nstate.info.authorization.manage_location[key].authorization = 
				nstate.info.authorization.manage_location[key].authorization.filter(x => x !== e.target.name);
		}


		this.setState(nstate);

		console.log(nstate.info.authorization.manage_location[key].authorization);
		console.log(e.target.checked);

	}


	handleInput(e) {
		let nstate = this.state;

		_.set(nstate, 'info.'+e.target.name, e.target.value);

		this.setState(nstate);
		console.log(e.target.name);
	}

	handelLocationChange(e, name) {
		let nstate = this.state;

		let newlocs = {};

		e.map(function(x) { 
			newlocs['loc_'+x.value] = x;
			return x;
		});
		nstate.info.authorization.manage_location = newlocs;



		this.setState(nstate);
		
	}

	formSubmit(e) {
		e.preventDefault();
	}

	componentDidMount() {
		let that = this;

		axios.get('/api/user/'+this.props.match.params.userid)
		.then(function(response) {
			let nstate = that.state;
			let formLocs = [];
			_.set(nstate, 'info', response.data);

			//that.setState({info: response.data});

			Object.keys(nstate.info.authorization.manage_location).map(function(k, i) {
				
				formLocs.push({
					label: nstate.info.authorization.manage_location[k].location_name,
					value: nstate.info.authorization.manage_location[k].location_id
				});

				return k;
				
			});

			_.set(nstate, 'form.locations', formLocs);

			that.setState(nstate);

			that.props.doneLoading();
			
		});


		

		axios.get('/api/locations?token='+this.props.user.token)	
		.then(function(response) {
			let nstate = that.state;


			_.set(nstate, 'locations', response.data.locations.to);

			
			that.setState(nstate);

			console.log(that.state);
		});
	}

	render() {

		const locations = this.state.locations
		return(
			<div>
				<form method="#" onSubmit={this.formSubmit}>
				<div className={"form-group"}>
					<label>Username</label>
					<input type="text" className={"form-control"} name="username" value={this.state.info.username} onChange={this.handleInput} />
				</div>

				<div className={"form-group"}>
					<label>First name</label>
					<input type="text" className={"form-control"} name="firstName" value={this.state.info.firstName} onChange={this.handleInput} />
				</div>

				<div className={"form-group"}>
					<label>Last name</label>
					<input type="text" className={"form-control"} name="lastName" value={this.state.info.lastName} onChange={this.handleInput} />
				</div>

				<div className={"form-group"}>
					<label>Role</label>
					<input type="text" className={"form-control"} name="role" value={this.state.info.role} onChange={this.handleInput}/>
				</div>
				<h5>Attributed locations</h5>

				
  				<Select
  				onChange={this.handelLocationChange}
  				isMulti
  				options={[...locations.map(function(x) { return { authorization: [], 'label': x.location_name, 'value': x.location_id} } )]}
  				value={[...Object.keys(this.state.info.authorization.manage_location).map(x => this.state.info.authorization.manage_location[x])]}
  				className={"col-md-12"}
  				/>
  				

  				{Object.keys(this.state.info.authorization.manage_location).map((x, i) =>
  					(


  					<div className={"card mt-3"}>
					<div className={"card-header"}>
					{this.state.info.authorization.manage_location[x].label}
					<button 
					className={"btn btn-primary btn-sm float-right ml-1"} 
					data-toggle="collapse" 
					data-target={"#card_id_"+i}>
					Toggle
					</button>
					</div>


					<div className={"card-body collapse"} id={"card_id_"+i}>


						<div className={"form-group"}>
						<h5>Authorizations</h5><br />

						<div class="custom-control custom-checkbox">
						  <input 
						  name="confirm_order"
						  type="checkbox" 
						  class="custom-control-input" 
						  id={"customCheck3_"+ i}
						  data-loc-key={x}
						  onChange={this.handleCheckbox}
						  checked={(this.state.info.authorization.manage_location[x].authorization.indexOf('confirm_order') === -1) ? false : true} />
						  
						  <label class="custom-control-label" 
						  for={"customCheck3_"+ i}>Confirm order</label>
						</div>

						<div class="custom-control custom-checkbox">
						  <input
						  data-loc-key={x}
						  name="assign_driver" onChange={this.handleCheckbox} type="checkbox" class="custom-control-input" 
						  id={"customCheck2_"+ i} 
						  checked={(this.state.info.authorization.manage_location[x].authorization.indexOf('assign_driver') === -1) ? false : true} />
						
						  <label class="custom-control-label" 
						  for={"customCheck2_"+ i}>Assign driver</label>
						</div>

						<div class="custom-control custom-checkbox">
						  <input 
						  data-loc-key={x}
						  name="confirm_drop" onChange={this.handleCheckbox} type="checkbox" class="custom-control-input" 
						  id={"customCheck1_"+ i} checked={(this.state.info.authorization.manage_location[x].authorization.indexOf('confirm_drop') === -1) ? false : true} />
						  
						  <label 
						  class="custom-control-label" 
						  for={"customCheck1_"+ i}>Confirm dropoff</label>
						</div>

						
						</div>

					</div>

					</div>

  						))}
  				<div className={"mt-5"}>
  				<button className={"btn btn-success float-right"}>
  				Sauvegarder
  				</button>
  				</div>
  				</form>
			</div>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AgentSettingsDetail)