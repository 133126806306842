import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import Billing from './../Components/Admin/Billing';
import BillingDetail from '../Components/Admin/BillingDetail';
import PayBill from '../Components/Payment/PayBill';

import PayCCP from '../Components/Payment/PayCCP';



function mapStateToProps(state) {
  return { 
      user: state.user,
      auth: state.auth 
    };
};


class AdminRouter extends Component {

	
	render() {
		return(
				<Switch>

                    
                    {/* {(!this.props.auth || this.props.user.role !== 'admin') && (
                        
                        )} */}
                        <Route exact path='/payments' component={Billing} />
                        <Route exact path='/payments/:billid' component={BillingDetail} />
                        <Route exact path='/bill/:billid/pay' component={PayBill} />
							
						
				</Switch>


			
		)
	}
}

export default connect(mapStateToProps)(AdminRouter)