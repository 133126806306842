import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setAuthAction, startLoading, doneLoading } from '../../Redux/actions/index';
import api from '../../Utils/Api';
import { Trans } from "react-i18next";
import { faCheck, faTimes,  faCommentAlt,  faTag, faList, faChevronRight, faFileInvoiceDollar, faUser, faTable, faUserTag } from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
const remote = process.env.REACT_APP_REMOTE_URL ? process.env.REACT_APP_REMOTE_URL : 'https://salim.tictechs.tech';


const mapStateToProps = state => {
	return {
        user: state.user,
        auth: state.auth
	}
}

const mapDispatchToProps = dispatch => {
	return {
		isLoading: e => dispatch(startLoading(e)),
        doneLoading: () => dispatch(doneLoading()),
        setAuth: e => dispatch(setAuthAction(e))
	}
}


class Billing extends Component {


    constructor(props) {
        super(props);

        this.state = {
           data: [],
           status: [
               {
                   label: 'Pending',
                   value: 'pending'
               },
               {
                   label: 'Canceled',
                   value: 'canceled'
               }
           ]
        };

        this.approveBill = this.approveBill.bind(this)
        this.rejectBill = this.rejectBill.bind(this)

        
    }

    componentDidMount() {
        const that = this;

        api.get('/api/billing/fetch')
        .then(function(response) {
            that.setState({
                data: response.data.data
            })
        });
       
    }

    approveBill(billid) {
        const that = this;
        this.props.isLoading();
        api.post('/api/bill/'+billid+'/approve')
        .then(function(response) {
            console.log(response.data)
            that.props.doneLoading();
        })

        return false;
    }

    rejectBill(billid) {
        const that = this;
        this.props.isLoading();
        api.post('/api/bill/'+billid+'/reject')
        .then(function(response) {
            console.log(response.data)
            that.props.doneLoading();
        })

        return false;
    }

  

    render() {
        return (<React.Fragment>

            <div>

				<div className={"container-header"}>
                <Trans i18nKey="label_payment" />
				</div>
                <div className={"col-md-12"}>

                
                    <div className={"row head-titles ctable-row"}>

                        <div className={"col-md-1"}>ID</div>

                        <div className={"col-md-2"}><Trans i18nKey="user_username" /></div>

                        <div className={"col-md-2"}><Trans i18nKey="label_payment" /></div>

                        <div className={"col-md-1"}><Trans i18nKey="label_method" /></div>

                        <div className={"col-md-1"}><Trans i18nKey="label_amount" /></div>

                        <div className={"col-md-2"}><Trans i18nKey="label_datetime" /></div>

                        <div className={"col-md-1"}><Trans i18nKey="label_status" /></div>

                        <div className={"col-md-2"}></div>

                    </div>


                    {this.state.data && this.state.data.map(item => (
                        
                        <div className={"row ctable-row"}>

                            <div className={"col-md-1"}>{item.id}</div>

                            <div className={"col-md-2"}>{item.username}</div>

                            <div className={"col-md-2"}><Trans i18nKey={"label_payment_type_" + item.type} /></div>

                            <div className={"col-md-1"}><Trans i18nKey={"label_method_" + item.method} /></div>
                            
                            <div className={"col-md-1"}>{item.data.amount}</div>

                            <div className={"col-md-2"}>{item.datetime}</div>

                            <div className={"col-md-1"}><Trans i18nKey={"label_payment_status_"+item.status} /></div>

                            <div className={"col-md-2"}>
                                {/* <a rel="noopener noreferrer" target="_blank" href={remote+"/"+item.proof} className={"btn-sm btn-info"}>Proof</a><br /> */}
                                <a href={remote+"/"+item.proof} className={"ml-1 btn-sm btn-info"} ><FontAwesomeIcon icon={faFileInvoiceDollar} /></a>
                                <a href="#" className={"ml-1 btn-sm btn-success"} onClick={e => this.approveBill(item.id)}><FontAwesomeIcon icon={faCheck} /></a><br />
                                <a href="#" className={"ml-1 btn-sm btn-danger"} onClick={e => this.rejectBill(item.id)}><FontAwesomeIcon icon={faTimes} /></a>
                                <Link to={"/payments/"+item.id} className={"ml-1 btn-sm btn-info"} ><FontAwesomeIcon icon={faChevronRight} /></Link>
                                </div>

                        </div>
                        
                    ))}

                </div>



            </div>
        </React.Fragment>)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Billing)